import { env } from '~/env'
import ContentLengthsStore from './contentLengths.store'
import PdfChunksStore from './pdfChunks.store'

const isConstructable = Symbol('isConstructable')

export default class mbueIDB {
  private static dbName = 'mbue'
  private static storeName = 'pdfChunks'
  private static contentLengthsStoreName = 'contentLengths'
  private static version = 1
  private db: IDBDatabase | null = null
  contentLengthStore: ContentLengthsStore
  pdfChunkStore: PdfChunksStore

  constructor(db: IDBDatabase, _isConstructable: typeof isConstructable) {
    if (_isConstructable !== isConstructable) {
      throw new Error('Cannot construct PdfStorage, use PdfStorage.new()')
    }

    this.db = db
    this.contentLengthStore = new ContentLengthsStore(db)
    this.pdfChunkStore = new PdfChunksStore(db)
  }

  static async new(): Promise<mbueIDB> {
    indexedDB.deleteDatabase(
      `mbue-pdf-storage-${env.VITE_ENV === 'prod' ? '' : env.VITE_ENV}`
    )

    const init = (): Promise<IDBDatabase> =>
      new Promise((resolve, reject) => {
        const openRequest = indexedDB.open(this.dbName, this.version)

        openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
          const db = (event.target as IDBOpenDBRequest).result

          if (!db.objectStoreNames.contains(this.storeName)) {
            const store = db.createObjectStore(this.storeName, {
              keyPath: ['url', 'rangeStart', 'rangeEnd']
            })
            store.createIndex('urlIndex', 'url', { unique: false })
          }

          if (!db.objectStoreNames.contains(this.contentLengthsStoreName)) {
            const store = db.createObjectStore(this.contentLengthsStoreName, {
              keyPath: 'url'
            })
            store.createIndex('jobIdIndex', 'jobId', { unique: true })
          }

          resolve(db)
        }

        openRequest.onsuccess = (event: Event) => {
          const db = (event.target as IDBOpenDBRequest).result
          resolve(db)
        }

        openRequest.onerror = (error) => {
          reject({
            status: 'error',
            message: `Database ${this.dbName} failed to open`,
            error
          })
        }
      })

    const db = await init()
    return new mbueIDB(db, isConstructable)
  }

  close(): void {
    this.db?.close()
  }

  static async exists(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(mbueIDB.dbName)

      request.onupgradeneeded = function () {
        request?.transaction?.abort() // Abort to avoid creating the database
        resolve(false)
      }

      request.onsuccess = function () {
        request.result.close()
        resolve(true)
      }

      request.onerror = function () {
        reject(request.error)
      }
    })
  }
}

/**
 * Utility to update a record in IndexedDB in the browser
 * Useful for debugging
const request = indexedDB.open('mbue', 1);
request.onsuccess = function(event) {
  const db = event.target.result;
  const transaction = db.transaction(['contentLengths'], 'readwrite');
  const store = transaction.objectStore('contentLengths');

  // Retrieve the record to be updated
  const getRequest = store.get('https://mbue-uploads-test.s3.us-west-2.amazonaws.com/982/comparisons/5179/smart_diff.pdf'); // Use the key of the record

  getRequest.onsuccess = function() {
      const record = getRequest.result;

      // Modify the fields you want to update
      record.createdAt = '2024-10-01T01:00:00Z';

      // Save the updated record
      const updateRequest = store.put(record);

      updateRequest.onsuccess = function() {
          console.log('Record updated successfully');
      };

      updateRequest.onerror = function() {
          console.error('Failed to update the record');
      };
  };
};
 */
