import { useEffect, useState } from 'react'
import { Download as DownloadIcon } from 'lucide-react'
import Button from '~/components/ui/Button'
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/ui/DropdownMenu'
import Mixpanel from '~/utilities/mixpanel/mixpanel'

interface DownloadsMenuButton {
  diffUrl?: string
  originalUrl: string
  revisedUrl: string
  smartReportUrl?: string
}

export default function DownloadsMenuButton({
  diffUrl,
  originalUrl,
  revisedUrl,
  smartReportUrl
}: DownloadsMenuButton): JSX.Element {
  const [isThereSmartReport, setIsThereSmartReport] = useState<boolean>(true)

  const handleDownload = (type: 'diff' | 'original' | 'revised' | 'smart_report') => {
    Mixpanel.track({
      event: 'FE:view-results',
      data: {
        mode: 'download',
        type
      }
    })
  }

  useEffect(() => {
    if (smartReportUrl) {
      fetch(smartReportUrl)
        .then((response) => !response.ok && setIsThereSmartReport(false))
        .catch(() => setIsThereSmartReport(false))
    }
  }, [smartReportUrl])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" className="h-10 flex gap-2">
          <DownloadIcon size={16} />
          <span>Download</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem disabled={!diffUrl}>
          <a
            className="block"
            href={diffUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleDownload('diff')}
          >
            Diff PDF
          </a>
        </DropdownMenuItem>
        {isThereSmartReport && (
          <DropdownMenuItem disabled={!isThereSmartReport}>
            <a
              className="block"
              href={smartReportUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => handleDownload('smart_report')}
            >
              Smart Report CSV
            </a>
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <a
            className="block"
            href={originalUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleDownload('original')}
          >
            Original PDF
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a
            className="block"
            href={revisedUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleDownload('revised')}
          >
            Revised PDF
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
