import { useState, useEffect, useRef } from 'react'
import { Navigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useDevCycleClient } from '@devcycle/react-client-sdk'
import { useAuth } from '~/hooks/useAuth'
import Mixpanel from '~/utilities/mixpanel'
import { useAcceptInviteQuery } from '~/graphql/hooks/useAcceptInviteQuery'

import Loading from '~/components/ui/Loading'

const Signup = () => {
  const userIdentifiedRef = useRef<boolean>(false)
  const devcycleClient = useDevCycleClient()
  const [loading, setLoading] = useState<boolean>(true)
  const { token, setToken, getUser } = useAuth()
  const [params] = useSearchParams()
  const loginToken = params.get('token')
  const inviteToken = localStorage.getItem('invite_token') ?? ''

  const { loading: acceptInviteLoading } = useAcceptInviteQuery(inviteToken, {
    onCompleted() {
      localStorage.removeItem('invite_token')
    }
  })

  useEffect(() => {
    const runEffect = async () => {
      if (loginToken) {
        setToken(loginToken)

        const user = await getUser()
        setLoading(false)
        if (!user) return

        if (!userIdentifiedRef.current && user?.me?.email) {
          userIdentifiedRef.current = true
          Mixpanel.identify({
            id: user.me.id.toString(),
            data: {
              email: user.me.email,
              framework: 'react'
            }
          })

          Mixpanel.track({
            event: 'FE:login',
            data: {
              framework: 'react',
              source: 'email-login'
            }
          })
        }
      }
    }

    if (!loginToken) {
      setLoading(false)
      return
    }

    void runEffect()
  }, [loginToken, setToken, getUser, devcycleClient])

  if (loading || acceptInviteLoading) return <Loading />
  if (token) return <Navigate to="/org" />
  return <Navigate to="/login" />
}

export default Signup
