import { Component, SVGProps } from 'react'

interface CustomWindow extends Window {
  mbueDualScrollButton: HTMLElement
}

declare let window: CustomWindow

interface DualScrollHeaderButtonProps {
  jobType: string
  onClick?: () => void
}

interface DualScrollHeaderButtonState {
  isHovered: boolean
  isActive: boolean
}

const ArrowUpDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={20}
    height={20}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-arrow-down-up"
    {...props}
  >
    <path d="m3 16 4 4 4-4M7 20V4M21 8l-4-4-4 4M17 4v16" />
  </svg>
)

export default class DualScrollHeaderButton extends Component<
  DualScrollHeaderButtonProps,
  DualScrollHeaderButtonState
> {
  constructor(props: DualScrollHeaderButtonProps) {
    super(props)
    this.state = {
      isHovered: false,
      isActive: true
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'd' || event.key === 'D') {
      this.handleClick()
    }
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true })
  }

  handleMouseLeave = () => {
    this.setState({ isHovered: false })
  }

  handleClick = () => {
    this.setState((prevState) => ({ ...prevState, isActive: !prevState.isActive }))
    window.mbueDualScrollButton?.click()
  }

  render() {
    if (this.props.jobType !== 'text') return null
    const { isHovered, isActive } = this.state

    return (
      <button
        data-element="dualScrollButton"
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={{
          backgroundColor: isActive ? '#e64486' : isHovered ? '#1e1e1e' : 'transparent',
          color: '#F8F9FA',
          borderColor: 'transparent',
          width: '32px',
          height: '32px',
          borderRadius: '4px',
          padding: '0',
          margin: '0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ArrowUpDownIcon />
      </button>
    )
  }
}
