// // Types will be defined in the ticket https://mbue.atlassian.net/browse/MB-743
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Core, WebViewerInstance } from '@pdftron/webviewer'
import {
  Annotation,
  AnnotationPayload,
  getTextAnnotationDiffString,
  transformAnnotation
} from '../annotations'

export const setDefaultViewerSettings = (instance: WebViewerInstance) => {
  const { UI } = instance

  UI.setTheme('dark')
  UI.disableElements([
    'toolbarGroup-Forms',
    'toolbarGroup-View',
    'toolbarGroup-FillAndSign',
    'annotationContentOverlay',
    'contextMenuPopup',
    'zoom-button-marquee-zoom'
  ])

  attachMouseWheelScroll(instance)

  const LayoutMode = instance.UI.LayoutMode
  instance.UI.setLayoutMode(LayoutMode.Single)
}

const attachMouseWheelScroll = (instance: WebViewerInstance) => {
  const { UI, Core } = instance

  for (const documentViewer of Core.getDocumentViewers()) {
    const documentContainer = documentViewer.getScrollViewElement()
    documentContainer.addEventListener('wheel', ((e: WheelEvent) => {
      e.stopPropagation()
      e.preventDefault()

      const step = ((1 * e.deltaY) / 8) * 0.01
      let zoom = documentViewer.getZoomLevel() - step
      zoom = Math.max(zoom, UI.getMinZoomLevel())
      zoom = Math.min(zoom, UI.getMaxZoomLevel())
      documentViewer.zoomToMouse(zoom, 0, 0)
    }) as EventListener)

    const Feature = instance.UI.Feature
    instance.UI.disableFeatures([Feature.PageNavigation])
  }
}

export const drawAnnotations = (
  instance: WebViewerInstance,
  annotations: AnnotationPayload
) => {
  if (!annotations) return

  const { annotationManager } = instance.Core

  annotationManager.enableReadOnlyMode()

  annotations.pages.forEach((page: { annotations: Annotation[] }, i: number) => {
    const pageNumber = i + 1
    const transformedAnnotations = page.annotations.map((annot: Annotation) => {
      const polyAnnotation = transformAnnotation(annot, instance, false)
      if (polyAnnotation) {
        polyAnnotation.PageNumber = pageNumber
        polyAnnotation.Id = annot.uuid ?? ''
      }
      return polyAnnotation
    })
    if (transformedAnnotations) {
      annotationManager.addAnnotations(
        transformedAnnotations.filter((a) => a !== null),
        { imported: true }
      )
    }
  })
}

export const addAnnotationTooltips = (instance: WebViewerInstance) => {
  const { UI, Core } = instance
  const { annotationManager } = Core

  if (!UI.iframeWindow?.document) {
    console.error('iframeWindow or iframeDocument is not defined')
    return
  }

  if (!annotationManager) {
    console.error('AnnotationManager is not defined, failed to add tooltips')
    return
  }

  const tooltip = document.createElement('div')
  tooltip.style.display = 'none'
  tooltip.style.position = 'absolute'
  tooltip.style.zIndex = '999'
  tooltip.style.backgroundColor = '#1e1e1e'
  tooltip.style.color = 'white'
  tooltip.style.padding = '6px 12px'
  tooltip.style.borderRadius = '8px'
  tooltip.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.2)'

  document.body.appendChild(tooltip)

  const iframeDoc = UI.iframeWindow.document
  const iframeWindow = UI.iframeWindow

  const mouseMoveHandler = (e: MouseEvent) => {
    try {
      const annotation = annotationManager.getAnnotationByMouseEvent(e)

      if (annotation) {
        tooltip.style.display = 'block'
        tooltip.style.left = e.pageX + iframeWindow.scrollX + 'px'
        tooltip.style.top = e.pageY + iframeWindow.scrollY + 20 + 'px'
        tooltip.innerText = annotation.getContents
          ? annotation.getContents()
          : 'No contents'
      } else {
        tooltip.style.display = 'none'
      }
    } catch (error) {
      console.error('Error in mousemove handler:', error)
      tooltip.style.display = 'none'
    }
  }

  iframeDoc.body.addEventListener('mousemove', mouseMoveHandler)

  return () => {
    iframeDoc.body.removeEventListener('mousemove', mouseMoveHandler)
    document.body.removeChild(tooltip)
  }
}

export const loadTextCompare = (
  instance: WebViewerInstance,
  original: string,
  revised: string
) => {
  const { UI, Core } = instance

  UI.setLayoutMode(UI.LayoutMode.Continuous)

  UI.addEventListener(UI.Events.MULTI_VIEWER_READY, () => {
    const tabOptions: Core.LoadDocumentOptions = {
      extension: 'pdf'
    }

    const [viewer1, viewer2] = Core.getDocumentViewers()

    UI.iframeWindow.document
      .querySelectorAll('[aria-label="Start Sync"]')
      .forEach((button) => {
        ;(button as HTMLElement).style.display = 'none'
      })

    let isDualScroll = true
    let isScrolling = false // Prevent scroll loops
    const lastScrollTop = { docContainer1: 0, docContainer2: 0 } // Last vertical positions

    const docContainer1 = UI.iframeWindow.document.querySelector('#DocumentContainer1')
    const docContainer2 = UI.iframeWindow.document.querySelector('#DocumentContainer2')

    const toggleButton = document.createElement('button')
    toggleButton.id = 'mbueDualScrollButton'
    toggleButton.style.position = 'fixed'
    toggleButton.style.display = 'hidden'
    document.body.appendChild(toggleButton)

    toggleButton.addEventListener('click', () => {
      if (!isDualScroll) {
        // Capture current scroll positions when enabling
        lastScrollTop.docContainer1 = docContainer1!.scrollTop
        lastScrollTop.docContainer2 = docContainer2!.scrollTop

        console.log('Dual scrolling enabled')
      } else {
        console.log('Dual scrolling disabled')
      }
      isDualScroll = !isDualScroll
    })

    // Function to handle dual vertical scrolling
    function syncVerticalScroll(
      source: HTMLElement | Element,
      target: HTMLElement | Element,
      sourceKey: 'docContainer1' | 'docContainer2',
      targetKey: 'docContainer1' | 'docContainer2'
    ) {
      if (!isDualScroll || isScrolling) return // Exit if dual scroll is disabled or already handling scroll

      isScrolling = true // Prevent recursion during sync

      // Calculate the vertical delta
      const deltaTop = source.scrollTop - lastScrollTop[sourceKey]

      // Check if the target has reached its scroll limit
      const targetMaxScroll = target.scrollHeight - target.clientHeight
      const newTargetScrollTop = target.scrollTop + deltaTop

      // Always update the target's scroll, keeping its previous progress
      if (newTargetScrollTop >= 0 && newTargetScrollTop <= targetMaxScroll) {
        target.scrollTop += deltaTop
      } else if (newTargetScrollTop > targetMaxScroll) {
        // If we'd exceed max scroll, just set to max
        target.scrollTop = targetMaxScroll
      } else if (newTargetScrollTop < 0) {
        // If we'd go below 0, just set to 0
        target.scrollTop = 0
      }

      // Update last vertical scroll position
      lastScrollTop[sourceKey] = source.scrollTop

      // Ensure the target's last known position is preserved
      lastScrollTop[targetKey] = target.scrollTop

      // Allow sync to settle
      setTimeout(() => (isScrolling = false), 0)
    }

    docContainer1?.addEventListener('scroll', () =>
      syncVerticalScroll(docContainer1, docContainer2!, 'docContainer1', 'docContainer2')
    )

    docContainer2?.addEventListener('scroll', () =>
      syncVerticalScroll(docContainer2, docContainer1!, 'docContainer2', 'docContainer1')
    )

    // Set up a promise to wait for both documents to be loaded
    void Promise.all([
      new Promise((resolve) => viewer1.addEventListener('documentLoaded', resolve)),
      new Promise((resolve) => viewer2.addEventListener('documentLoaded', resolve))
    ])

    void viewer1.loadDocument(original, { ...tabOptions, filename: 'Newest' })
    void viewer2.loadDocument(revised, { ...tabOptions, filename: 'Original' })

    // documentsLoaded
    //   .then(async () => {
    //     await runTextCompare(instance)
    //   })
    //   .catch((error) => console.error(error))
  })

  UI.enterMultiViewerMode()
}

export const runTextCompare = async (instance: WebViewerInstance) => {
  const { UI, Core } = instance

  const [viewer1, viewer2] = Core.getDocumentViewers()

  const beforeColor = new Core.Annotations.Color(255, 73, 73, 0.4)
  const afterColor = new Core.Annotations.Color(21, 205, 131, 0.4)

  const options = { beforeColor, afterColor }
  const { doc1Annotations, doc2Annotations, diffCount }: any =
    await viewer1.startSemanticDiff(viewer2, options)

  // Because annotations are on separate documents we create new managers for both
  const annotationManagerOriginal = new Core.AnnotationManager(viewer1)
  annotationManagerOriginal.addAnnotations(doc1Annotations)

  const annotationManagerRevised = new Core.AnnotationManager(viewer2)
  annotationManagerRevised.addAnnotations(doc2Annotations)

  // We create a bridge to link annotations from the original document to the revised document by their TextDiffID.
  const annotationBridge = new Map<
    string,
    { original?: Core.Annotations.Annotation; revised?: Core.Annotations.Annotation }
  >()

  const combinedAnnotations = [
    ...doc1Annotations.map((annotation: Core.Annotations.Annotation) => ({
      annotation,
      type: 'original'
    })),
    ...doc2Annotations.map((annotation: Core.Annotations.Annotation) => ({
      annotation,
      type: 'revised'
    }))
  ]

  combinedAnnotations.forEach(({ annotation, type }) => {
    const id = annotation.getCustomData('TextDiffID')
    const existingEntry = annotationBridge.get(id) ?? {}

    annotationBridge.set(id, {
      ...existingEntry,
      [type]: annotation
    })
  })

  const getText = (annotation: Core.Annotations.Annotation | undefined): string => {
    if (!annotation) {
      return 'None'
    }

    // For some reason Author holds the text information...
    const content =
      annotation.getContents() !== '' ? annotation.getContents() : annotation.Author
    if (content !== '') {
      return content
    }
    return 'None'
  }

  // Sanity check
  if (annotationBridge.size === diffCount) {
    const tooltip = document.createElement('mbue-tooltip')
    tooltip.style.display = 'none'
    tooltip.style.position = 'absolute'

    document.body.appendChild(tooltip)

    const iframeDoc = UI.iframeWindow.document
    const iframeWindow = UI.iframeWindow

    iframeDoc.body.addEventListener('mousemove', function (e: MouseEvent) {
      const viewerAnnotationOne = annotationManagerOriginal.getAnnotationByMouseEvent(e)
      const viewerAnnotationTwo = annotationManagerRevised.getAnnotationByMouseEvent(e)

      // Depending which viewer holds the annotations.
      const annotation = viewerAnnotationOne || viewerAnnotationTwo

      if (annotation) {
        const id = annotation.getCustomData('TextDiffID')
        tooltip.style.display = 'block'

        // Position and style tooltip
        tooltip.style.left = e.pageX + iframeWindow.scrollX + 'px'
        tooltip.style.top = e.pageY + iframeWindow.scrollY + 'px'
        tooltip.style.backgroundColor = '#1e1e1e'
        tooltip.style.color = 'white'
        tooltip.style.padding = '6px 12px'
        tooltip.style.borderRadius = '8px'
        tooltip.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.2)'

        const originalText = getText(annotationBridge.get(id)?.original)
        const revisedText = getText(annotationBridge.get(id)?.revised)

        tooltip.innerText = getTextAnnotationDiffString(originalText, revisedText)
      } else {
        tooltip.style.display = 'none'
      }
    })
  }
}
