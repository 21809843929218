import { env } from '~/env'
import { initializeDevCycle } from '@devcycle/js-client-sdk'
import { getUser } from '~/utilities/getUserFromLS'

export default class FilesCache {
  static init(): void {
    const user = getUser()
    if (!user) {
      console.log('>> SW: User has to be logged in to use the service worker')
      return
    }

    const devcycle = initializeDevCycle(env.VITE_DEVCYCLE_KEY, {
      user_id: user.user_id,
      email: user.email
    })
    devcycle
      .onClientInitialized()
      .then(async (client) => {
        const isCacheEnabled = client.variableValue('indexed-db-cache-enabled', false)
        if (isCacheEnabled) {
          FilesCache.register()
        } else {
          const swUnregistered = await FilesCache.unregister()
          if (swUnregistered) {
            indexedDB.deleteDatabase('mbue')
          }
        }
      })
      .catch((error) => {
        console.error('[DevCycle]: client initialization failed', error)
      })
  }

  static register(): void {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js?v=0.1.0', { type: 'module' })
          .then((registration) => {
            console.log('SW: Service Worker registered with scope:', registration.scope)
          })
          .catch((error) => {
            console.log('SW: Service Worker registration failed:', error)
          })
      })
    }
  }

  static async unregister(): Promise<boolean> {
    if ('serviceWorker' in navigator) {
      try {
        // Get all service worker registrations
        const registrations = await navigator.serviceWorker.getRegistrations()

        // Unregister each service worker
        const unregisterResults = await Promise.all(
          registrations.map((registration) => {
            console.log(
              'SW: Unregistering service worker with scope:',
              registration.scope
            )
            return registration.unregister()
          })
        )

        // Check if all service workers were unregistered successfully
        const allUnregistered = unregisterResults.every((result) => result === true)

        if (allUnregistered) {
          console.log('SW: All service workers have been unregistered successfully')
          return true
        } else {
          console.log('SW: Some service workers could not be unregistered')
          return false
        }
      } catch (error) {
        console.error('SW: Error unregistering service workers:', error)
        return false
      }
    }
    return false
  }
}
