import { env } from '~/env'
import { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { withDevCycleProvider } from '@devcycle/react-client-sdk'
import { useDevcycle } from '~/hooks/useDevcycle'
import { getUser } from '~/utilities/getUserFromLS'

import AcceptInvite from './Auth/AcceptInvite'
import Error404 from './Error404'
import Jobs from './Admin/Jobs'
import Layout from './Layout'
import Login from './Auth/Login'
import OrgRedirect from './Org'
import OrgSettings from './Org/OrgSettings'
import Pricing from './Org/Pricing'
import Projects from './Org/Projects/Projects'
import ProtectedRoute from 'components/ProtectedRoute'
import Register from './Auth/Register'
import Reviews from './Org/Projects/Reviews'
import Signup from './Auth/Login/Signup'
import Loading from '~/components/ui/Loading'

const Onboarding = lazy(() => import('./Onboarding'))
const Viewer = lazy(() => import('./Org/Projects/Viewer'))

function App() {
  useDevcycle()

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/invite-link" element={<AcceptInvite />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/onboarding"
          element={
            <ProtectedRoute>
              <Onboarding />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Login />} />

        <Route path="/" element={<Layout />}>
          <Route path="/org" element={<OrgRedirect />} />
          <Route
            path="/org/:orgId/project/:projectId/reviews"
            element={
              <ProtectedRoute>
                <Reviews />
              </ProtectedRoute>
            }
          />
          <Route
            path="/org/:orgId/project/:projectId/reviews/:reviewId"
            element={
              <ProtectedRoute>
                <Reviews />
              </ProtectedRoute>
            }
          />
          <Route
            path="/org/:orgId/projects"
            element={
              <ProtectedRoute>
                <Projects />
              </ProtectedRoute>
            }
          />
          <Route
            path="/org/:orgId/project/:projectId/reviews/:reviewId/view"
            element={
              <ProtectedRoute>
                <Viewer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/org/:orgId/pricing"
            element={
              <ProtectedRoute>
                <Pricing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/org/:orgId/settings"
            element={
              <ProtectedRoute>
                <OrgSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/jobs"
            element={
              <ProtectedRoute admin={true}>
                <Jobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/jobs/:projectId/:reviewId"
            element={
              <ProtectedRoute admin={true}>
                <Jobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Error404 />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  )
}

const AppWithDevcycle = withDevCycleProvider({
  sdkKey: env.VITE_DEVCYCLE_KEY,
  user: getUser(),
  options: {
    logLevel:
      env.VITE_ENV === 'development' && !window.location.origin.includes('localhost')
        ? 'debug'
        : 'error',
    deferInitialization: getUser() ? false : true
  }
})(App)

export default AppWithDevcycle
