import { useDevCycleClient, useIsDevCycleInitialized } from '@devcycle/react-client-sdk'
import { useUser } from '~/graphql/hooks/useUser'

let isUserIdentified = false

export function useDevcycle() {
  const { user } = useUser()
  const devcycleClient = useDevCycleClient()
  const isDevcycleInitialized = useIsDevCycleInitialized()

  if (user && !isUserIdentified) {
    devcycleClient
      .identifyUser({
        user_id: user?.id.toString(),
        email: user?.email
      })
      .catch((error) => `[DevCycle]: ${error})`)
    isUserIdentified = true
  }

  return {
    devcycleClient,
    isDevcycleInitialized
  }
}
